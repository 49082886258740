import React, { CSSProperties, useEffect, useState } from 'react'
import GreenCheckCircle from '../assets/GreenCheckCircle'
import Error from '../assets/Error'
import Warning from '../assets/Warning'
import { IntgModal } from '.'
import Button, { GhostButton } from '../components/Button'
import Divider from '../components/Divider'
import Modal, { ButtonWrapper } from '../components/Modal'
import { HeadingMedium, HeadingSmallest } from '../components/Text'
import theme from '../Theme'
import { CodeBlock } from '../components/CodeBlock'
import styled from 'styled-components'
import { TextField } from '../components/InputFields'
import { backendRequest, addImpersonationParams } from '../utils/utils'
import Loading from '../assets/Loading'
import useImpersonation from '../hooks/useImpersonation'
import { EmailFilterStrategy } from '.'
import { showToast, ToastType } from '../utils/toastify'

const removeGoogleWorkspaceIntegration = async (
  setModal: (value: React.SetStateAction<IntgModal>) => void,
  onDismiss: () => void,
  adminEmail: string,
  impersonate: boolean,
  reqOrgId: number,
  reqUserId: number
) => {
  const googleWorkspacePath = addImpersonationParams(
    `/integrations/google_workspace?admin_email=${adminEmail}`,
    impersonate,
    reqOrgId,
    reqUserId,
    false
  )

  const resp = await backendRequest(googleWorkspacePath, {
    method: 'DELETE',
  })
  if (resp.error) {
    setModal(IntgModal.GoogleWorkspaceFail)
    return
  }
  showToast(
    'Google Workspace integration removed successfully',
    ToastType.SUCCESS
  )
  onDismiss()
}

const redirectToGoogleWorkspace = () => {
  window.open(
    'https://admin.google.com/u/1/ac/owl/domainwidedelegation?hl=en',
    '_blank'
  )
}

export const GoogleWorkspaceModalHandler = ({
  modal,
  onDismiss,
  setModal,
}: {
  modal: IntgModal
  onDismiss: () => void
  setModal: (value: React.SetStateAction<IntgModal>) => void
}) => {
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()
  const [adminEmail, setAdminEmail] = useState('')
  const [adminEmails, setAdminEmails] = useState<string[] | undefined | null>(
    []
  )

  useEffect(() => {
    const getAdminEmails = async () => {
      const adminEmailsPath = addImpersonationParams(
        '/integrations/google_workspace/admin_emails',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(adminEmailsPath)
      if (resp.error) {
        setAdminEmails(null)
        return
      }
      setAdminEmails(resp)
    }
    getAdminEmails()
  }, [impersonate, reqOrgId, reqUserId])
  switch (modal) {
    case IntgModal.GoogleWorkspaceAddRemove:
      return (
        <GoogleWorkspaceAddRemoveModal
          adminEmails={adminEmails}
          setAdminEmail={setAdminEmail}
          setModal={setModal}
          onDismiss={onDismiss}
        />
      )
    case IntgModal.GoogleWorkspaceRedirect:
      redirectToGoogleWorkspace()
      return (
        <GoogleWorkspaceRedirectModal
          adminEmails={adminEmails}
          setModal={setModal}
          onDismiss={onDismiss}
        />
      )
    case IntgModal.GoogleWorkspaceSuccess:
      return <GoogleWorkspaceSuccessModal onDismiss={onDismiss} />
    case IntgModal.GoogleWorkspaceFail:
      return <GoogleWorkspaceFailModal onDismiss={onDismiss} />
    case IntgModal.GoogleWorkspaceRemove:
      return (
        <GoogleWorkspaceRemoveModal
          onClick={() =>
            removeGoogleWorkspaceIntegration(
              setModal,
              onDismiss,
              adminEmail,
              impersonate,
              reqOrgId,
              reqUserId
            )
          }
          onDismiss={onDismiss}
        />
      )
    case IntgModal.GoogleWorkspaceSettings:
      return <GoogleWorkspaceSettingsModal onDismiss={onDismiss} />
    default:
      return <></>
  }
}

const Highlighted = styled('span')`
  color: ${theme.color.errorRed};
`

export const GoogleWorkspaceRedirectModal = ({
  adminEmails,
  onDismiss,
  setModal,
}: {
  adminEmails: string[] | undefined | null
  onDismiss: () => void
  setModal: (value: React.SetStateAction<IntgModal>) => void
}) => {
  const [loading, setLoading] = useState(false)
  const [adminEmail, setAdminEmail] = useState('')
  const [error, setError] = useState<string>('')

  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  const onClick = async () => {
    if (adminEmails && adminEmails.includes(adminEmail)) {
      setError('A Google Workspace integration with this email already exists.')
      return
    }
    setLoading(true)
    const googleWorkspacePath = addImpersonationParams(
      '/integrations/google_workspace',
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const resp = await backendRequest(googleWorkspacePath, {
      method: 'POST',
      body: JSON.stringify({
        admin_email: adminEmail,
      }),
    })
    setLoading(false)
    if (resp.error) {
      setModal(IntgModal.GoogleWorkspaceFail)
      return
    }
    setModal(IntgModal.GoogleWorkspaceSuccess)
  }

  return (
    <GoogleWorkspaceModal
      onDismiss={onDismiss}
      onClick={onClick}
      canCancel={true}
      heading="Google Workspace Installation"
      body={
        loading ? (
          <Loading />
        ) : (
          <>
            <p>
              To install Google Workspace, it is necessary to have{' '}
              <Highlighted>Super Admin </Highlighted>
              privileges for your company's Google Workspace account.
            </p>
            <p>
              To proceed with the installation, copy and paste the following
              values into your Google Admin console:
            </p>
            <ul>
              <li>Client ID:</li>
            </ul>
            <CodeBlock>
              {process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT}
            </CodeBlock>
            <ul>
              <li>OAuth Scopes:</li>
            </ul>
            <CodeBlock>
              https://www.googleapis.com/auth/admin.directory.user.security,
              https://www.googleapis.com/auth/admin.directory.user.readonly,
              https://www.googleapis.com/auth/admin.reports.audit.readonly,
              https://www.googleapis.com/auth/admin.reports.usage.readonly,
              https://www.googleapis.com/auth/gmail.readonly
            </CodeBlock>
            <p>
              Upon completion of the installation process, we kindly request
              that you notify us by clicking the "
              <Highlighted>Confirm</Highlighted>" button below.
            </p>
            <TextField
              label={'Google Workspace Admin Email'}
              onChange={(email: string) => setAdminEmail(email)}
              errorMsg={error}
            />
          </>
        )
      }
      buttonTxt="Confirm"
      disableBtn={loading || !adminEmail}
      color="textPurple"
    />
  )
}
export const GoogleWorkspaceAddRemoveModal = ({
  adminEmails,
  onDismiss,
  setModal,
  setAdminEmail,
}: {
  adminEmails: string[] | undefined | null
  onDismiss: () => void
  setModal: (value: React.SetStateAction<IntgModal>) => void
  setAdminEmail: (email: string) => void
}) => {
  return (
    <Modal onDismiss={onDismiss} style={{ width: '560px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <HeadingMedium color="textDefault">
            Add/Remove Google Workspace Integrations
          </HeadingMedium>
        </div>
        <Divider />
        <HeadingSmallest>
          Diminish supports multiple Google Workspace instances per
          organization. Please select which integration you want to remove or
          click Add Integration to add a new Google Workspace Integration.
        </HeadingSmallest>
        <HeadingSmallest>
          Google Workspace Integrations currently active for your organization:
        </HeadingSmallest>
        {adminEmails && adminEmails.length > 0 ? (
          <HeadingSmallest>
            {adminEmails.map((email, idx) => {
              return (
                <>
                  <Divider />
                  <div
                    key={idx}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      height: '80px',
                      alignItems: 'center',
                    }}
                  >
                    <span>
                      Admin: <b>{email}</b>
                    </span>
                    <Button
                      color="errorRed"
                      onClick={() => {
                        setAdminEmail(email)
                        setModal(IntgModal.GoogleWorkspaceRemove)
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                </>
              )
            })}
          </HeadingSmallest>
        ) : (
          <></>
        )}
        <Divider />
        <ButtonWrapper>
          <GhostButton onClick={onDismiss} style={{ padding: '14px 24px' }}>
            Cancel
          </GhostButton>
          <Button onClick={() => setModal(IntgModal.GoogleWorkspaceRedirect)}>
            Add Integration
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}

export const GoogleWorkspaceSuccessModal = ({
  onDismiss,
}: {
  onDismiss: () => void
}) => {
  return (
    <GoogleWorkspaceModal
      onClick={onDismiss}
      canCancel={false}
      heading="Congratulations"
      body={
        <>
          Congratulations! Your Google Workspace integration is completed. You
          can now view your employee list directly in your Diminish account and
          access your application invoices on the Contracts page. Please note
          that it may take up to 24 hours for this information to become
          visible.
        </>
      }
      buttonTxt="Ok"
      Icon={GreenCheckCircle}
      color="successGreen"
    />
  )
}

export const GoogleWorkspaceFailModal = ({
  onDismiss,
}: {
  onDismiss: () => void
}) => {
  return (
    <GoogleWorkspaceModal
      onDismiss={onDismiss}
      onClick={onDismiss}
      canCancel={false}
      heading="Something went wrong"
      body={'Please try again.'}
      buttonTxt="Ok"
      Icon={Error}
      color="errorRed"
    />
  )
}

export const GoogleWorkspaceRemoveModal = ({
  onDismiss,
  onClick,
}: {
  onDismiss: () => void
  onClick: () => void
}) => {
  return (
    <GoogleWorkspaceModal
      onDismiss={onDismiss}
      onClick={onClick}
      canCancel={true}
      heading="Remove Integration"
      body={
        'Are you sure you want to remove the Google Workspace integration? Please note that doing so may result in the loss of crucial data such as employee lists, as well as your SaaS invoices and contracts.'
      }
      buttonTxt="Remove"
      Icon={Warning}
      color="errorRed"
    />
  )
}

export const GoogleWorkspaceSettingsModal = ({
  onDismiss,
}: {
  onDismiss: () => void
}) => {
  const [filteringStrategy, setFilteringStrategy] = useState<
    undefined | null | string
  >()
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  useEffect(() => {
    if (filteringStrategy !== undefined) {
      return
    }
    const getFilteringStrategy = async () => {
      const strategy = addImpersonationParams(
        '/contracts/filtering_strategy',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(strategy)
      if (resp.error) {
        setFilteringStrategy(null)
        return
      }
      setFilteringStrategy(resp.filtering_strategy)
    }
    getFilteringStrategy()
  }, [impersonate, reqOrgId, reqUserId, filteringStrategy])

  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '460px', borderColor: theme.color.textPurple }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <HeadingMedium color="textDefault">
            Google Workspace Configuration
          </HeadingMedium>
        </div>
        <Divider />
        {!filteringStrategy ? (
          <Loading />
        ) : filteringStrategy === EmailFilterStrategy.FORBIDDEN_INBOXES ? (
          <ForbiddenInboxModal onDismiss={onDismiss} />
        ) : filteringStrategy === EmailFilterStrategy.PERMITTED_INBOXES ? (
          <PermittedInboxModal onDismiss={onDismiss} />
        ) : filteringStrategy === EmailFilterStrategy.NO_FILTER ? (
          <>
            No inboxes are currently being filtered for your account. Contact
            Diminish support to set it up.
          </>
        ) : filteringStrategy === EmailFilterStrategy.DISABLED_INBOXES ? (
          <>
            Inbox access is not enabled for your organization. Contact Diminish
            support to set it up.
          </>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  )
}

export const ForbiddenInboxModal = ({
  onDismiss,
}: {
  onDismiss: () => void
}) => {
  const [ignoreList, setIgnoreList] = useState<string[] | undefined | null>(
    undefined
  )
  const [ignoreListInput, setIgnoreListInput] = useState<string>('')
  const [submitErr, setSubmitErr] = useState<string>('')
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  useEffect(() => {
    if (ignoreList !== undefined) {
      return
    }
    const getIgnoreList = async () => {
      const ignoreListPath = addImpersonationParams(
        '/contracts/ignore_list',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(ignoreListPath)
      if (resp.error) {
        setSubmitErr(resp.error)
        setIgnoreList(null)
        return
      }
      setSubmitErr('')
      setIgnoreList(resp)
    }
    getIgnoreList()
  }, [ignoreList, impersonate, reqOrgId, reqUserId])

  const onSubmit = async () => {
    if (!ignoreListInput) {
      return
    }

    const ignoreListPath = addImpersonationParams(
      '/contracts/ignore_list',
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const resp = await backendRequest(ignoreListPath, {
      method: 'POST',
      body: JSON.stringify({
        ignore_list: ignoreListInput.split(',').map((email) => email.trim()),
      }),
    })

    if (resp.error) {
      setSubmitErr(resp.error)
      setIgnoreList(null)
      return
    }
    setIgnoreList(resp)
    setSubmitErr('')
    setIgnoreListInput('')
  }

  return (
    <>
      <HeadingSmallest>
        Please provide a list of inboxes that should be ignored by our system
        when looking for contracts.
      </HeadingSmallest>
      <TextField
        type="text"
        name="text"
        value={ignoreListInput}
        placeholder="alice@company.com, bob@company.com"
        marginBottom="5px"
        errorMsg={submitErr}
        label={'Email Address'}
        onChange={(newInput) => setIgnoreListInput(newInput)}
      />
      {ignoreList && ignoreList.length > 0 ? (
        <>
          <HeadingSmallest>
            Email addresses currently in the ignore list:
          </HeadingSmallest>
          <ul
            style={{
              fontWeight: 500,
              color: theme.color.textDefault,
              fontSize: '14px',
              margin: '0px',
            }}
          >
            {ignoreList.map((email, idx) => {
              return <li key={idx}>{email}</li>
            })}
          </ul>
        </>
      ) : (
        <></>
      )}
      <Divider />
      <ButtonWrapper>
        <GhostButton onClick={onDismiss}>Cancel</GhostButton>
        <Button onClick={onSubmit} disabled={!ignoreListInput}>
          Save
        </Button>
      </ButtonWrapper>
    </>
  )
}

export const PermittedInboxModal = ({
  onDismiss,
}: {
  onDismiss: () => void
}) => {
  const [allowList, setAllowList] = useState<string[] | undefined | null>(
    undefined
  )
  const [allowListInput, setAllowListInput] = useState<string>('')
  const [submitErr, setSubmitErr] = useState<string>('')
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  useEffect(() => {
    if (allowList !== undefined) {
      return
    }
    const getAllowList = async () => {
      const allowListPath = addImpersonationParams(
        '/contracts/allow_list',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(allowListPath)
      if (resp.error) {
        setSubmitErr(resp.error)
        setAllowList(null)
        return
      }
      setSubmitErr('')
      setAllowList(resp)
    }
    getAllowList()
  }, [allowList, impersonate, reqOrgId, reqUserId])

  const onSubmit = async () => {
    if (!allowListInput) {
      return
    }

    const allowListPath = addImpersonationParams(
      '/contracts/allow_list',
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const resp = await backendRequest(allowListPath, {
      method: 'POST',
      body: JSON.stringify({
        permitted_list: allowListInput.split(',').map((email) => email.trim()),
      }),
    })

    if (resp.error) {
      setSubmitErr(resp.error)
      setAllowList(null)
      return
    }
    setAllowList(resp)
    setSubmitErr('')
    setAllowListInput('')
  }

  return (
    <>
      <HeadingSmallest>
        Please provide the list of inboxes that our system should only look into
        when searching for contracts. Diminish will not access other inboxes.
      </HeadingSmallest>
      <TextField
        type="text"
        name="text"
        value={allowListInput}
        placeholder="alice@company.com, bob@company.com"
        marginBottom="5px"
        errorMsg={submitErr}
        label={'Email Address'}
        onChange={(newInput) => setAllowListInput(newInput)}
      />
      {allowList && allowList.length > 0 ? (
        <>
          <HeadingSmallest>
            Email addresses currently in the allow list:
          </HeadingSmallest>
          <ul
            style={{
              fontWeight: 500,
              color: theme.color.textDefault,
              fontSize: '14px',
              margin: '0px',
            }}
          >
            {allowList.map((email, idx) => {
              return <li key={idx}>{email}</li>
            })}
          </ul>
        </>
      ) : (
        <></>
      )}
      <Divider />
      <ButtonWrapper>
        <GhostButton onClick={onDismiss}>Cancel</GhostButton>
        <Button onClick={onSubmit} disabled={!allowListInput}>
          Save
        </Button>
      </ButtonWrapper>
    </>
  )
}

const GoogleWorkspaceModal = ({
  onDismiss,
  onClick,
  canCancel,
  heading,
  body,
  buttonTxt,
  disableBtn,
  Icon,
  color,
}: {
  onDismiss?: () => void
  onClick: () => Promise<void> | void
  canCancel: boolean
  heading: string
  body: string | JSX.Element
  buttonTxt: string
  disableBtn?: boolean
  Icon?: ({ style }: { style: CSSProperties }) => JSX.Element
  color: keyof typeof theme.color
}) => (
  <Modal
    onDismiss={onDismiss}
    style={{ width: '460px', borderColor: theme.color[color] }}
  >
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        {Icon ? <Icon style={{ height: '24px', width: '24px' }} /> : <></>}
        <HeadingMedium color="textDefault">{heading}</HeadingMedium>
      </div>
      <Divider />
      <HeadingSmallest>{body}</HeadingSmallest>
      <Divider />
      <ButtonWrapper>
        {canCancel && (
          <GhostButton
            onClick={onDismiss}
            style={{ padding: '14px 24px', borderColor: theme.color[color] }}
          >
            Cancel
          </GhostButton>
        )}
        <Button
          onClick={onClick}
          disabled={disableBtn}
          style={{
            backgroundColor: disableBtn
              ? theme.color.backgroundSubdued
              : theme.color[color],
            border: theme.color[color],
          }}
        >
          {buttonTxt}
        </Button>
      </ButtonWrapper>
    </div>
  </Modal>
)
