import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import QuickBooks from '../assets/logos/QuickBooks.png'
import Netsuite from '../assets/logos/Netsuite.png'
import Xero from '../assets/logos/Xero.png'
import Ramp from '../assets/logos/Ramp.png'
import IroncladIcon from '../assets/logos/ironcladIcon.png'
// import SageIntacct from '../assets/logos/SageIntacct.png'
import GoogleWorkspace from '../assets/logos/Google.png'
import Microsoft365 from '../assets/logos/Microsoft.png'
import Okta from '../assets/logos/Okta.png'
import Jamf from '../assets/logos/Jamf.jpeg'
import ZipHQ from '../assets/logos/Ziphq.jpeg'
import Chrome from '../assets/logos/Chrome.png'
import Firefox from '../assets/logos/Firefox.png'
import Safari from '../assets/logos/Safari.png'
import Edge from '../assets/logos/Edge.jpeg'
import GreenCheckCircle from '../assets/GreenCheckCircle'
import Divider from '../components/Divider'
import { HeadingMedium, HeadingSmallest } from '../components/Text'
import theme from '../Theme'
import { completeQuickbooksSetup, QuickbooksModalHandler } from './quickbooks'
import { completeXeroSetup, XeroModalHandler } from './xero'
import { IroncladModalHandler } from './ironclad'
import { NetsuiteModalHandler } from './netsuite'
import { GoogleWorkspaceModalHandler } from './google_workspace'
import { completeMicrosoftSetup, MicrosoftModalHandler } from './microsoft365'
import Loading from '../assets/Loading'
import { useMergeLink } from '@mergeapi/react-merge-link'
import GearIcon from '../assets/GearIcon'
import { backendRequest, addImpersonationParams } from '../utils/utils'
import { showToast, ToastType } from '../utils/toastify'
import useImpersonation from '../hooks/useImpersonation'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { HRISModalHandler } from './hris'
import CSV from '../assets/CSV.png'
import { OktaModalHandler } from './okta'
import { RampModalHandler } from './ramp'
import { JamfModalHandler } from './jamf'

const IntegrationWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.color.white,
  border: `1px solid ${theme.color.border}`,
  borderRadius: '4px',
  width: '310px',
  padding: '16px',
})

const CardsWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
  margin: '16px 0 40px',
})

const CardsContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '25px',
})

const LinkButton = styled('button')({
  border: 'none',
  backgroundColor: theme.color.white,
  color: theme.color.textPurple,
  fontWeight: 500,
  padding: '10px',
  cursor: 'pointer',
})

const ImageWrapper = styled('div')({
  border: `1px solid ${theme.color.border}`,
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  padding: '4px',
})

const IntegrationTitle = styled(HeadingMedium)({
  color: theme.color.textDefault,
  margin: 0,
})

const IntegrationCount = styled('span')({
  fontWeight: '400',
  fontSize: '14px',
})

export enum IntgModal {
  NoModal = 0,
  QuickbooksRedirect,
  QuickbooksSuccess,
  QuickbooksFail,
  QuickbooksRemove,
  GoogleWorkspaceAddRemove,
  GoogleWorkspaceRedirect,
  GoogleWorkspaceSuccess,
  GoogleWorkspaceFail,
  GoogleWorkspaceRemove,
  GoogleWorkspaceSettings,
  JamfCreate,
  JamfRemove,
  JamfSuccess,
  JamfFailure,
  JamfRemovalSuccess,
  NetsuiteRemove,
  NetsuiteFail,
  XeroRedirect,
  XeroSuccess,
  XeroFail,
  XeroRemove,
  SageIntacctRemove,
  SageIntacctFail,
  ZiphqCreate,
  ZiphqRemove,
  ZiphqSuccess,
  ZiphqFailure,
  ZiphqRemovalSuccess,
  IroncladRedirect,
  IroncladSuccess,
  IroncladFail,
  IroncladRemove,
  IroncladCustomerId,
  MicrosoftRedirect,
  MicrosoftSuccess,
  MicrosoftFail,
  MicrosoftRemove,
  MicrosoftSettings,
  OktaCreate,
  OktaRemove,
  OktaSuccess,
  OktaFailure,
  OktaRemovalSuccess,
  RampCreate,
  RampRemove,
  RampSuccess,
  RampFailure,
  RampRemovalSuccess,
  HRISCSVImporter,
}

export enum EmailFilterStrategy {
  PERMITTED_INBOXES = 'permitted_inboxes_only',
  FORBIDDEN_INBOXES = 'forbidden_inboxes',
  NO_FILTER = 'no_filter',
  DISABLED_INBOXES = 'disabled_inboxes',
}

type ConnectedIntegrations = {
  quickbooks: boolean
  netsuite: boolean
  xero: boolean
  ramp: boolean
  sage_intacct: boolean
  google_workspace: boolean
  microsoft_365: boolean
  jamf: boolean
  okta_logs: boolean
  ziphq: boolean
  ironclad_main: boolean
  ironclad_eu: boolean
  chrome_extension: boolean
  firefox_extension: boolean
  safari_extension: boolean
  edge_extension: boolean
}

const Integrations = () => {
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()
  const {
    microsoft365,
    ziphq,
    orgZiphq,
    ironclad,
    oktaLogs,
    oktaLogsAll,
    ramp,
    jamf,
    orgJamf,
  } = useFlags()

  const [integrations, setIntegrations] = useState<
    ConnectedIntegrations | undefined
  >()
  const [showModal, setShowModal] = useState<IntgModal>(0)
  const [linkTokenNS, setLinkTokenNS] = useState('')
  // const [linkTokenSI, setLinkTokenSI] = useState('')

  const [ironcladEnv, setIroncladEnv] = useState<string>('')

  const onClickQuickBooks = () => {
    if (integrations && integrations.quickbooks) {
      setShowModal(IntgModal.QuickbooksRemove)
      return
    }
    setShowModal(IntgModal.QuickbooksRedirect)
  }

  const onClickXero = () => {
    if (integrations && integrations.xero) {
      setShowModal(IntgModal.XeroRemove)
      return
    }
    setShowModal(IntgModal.XeroRedirect)
  }
  const onClickRamp = () => {
    if (integrations && integrations.ramp) {
      setShowModal(IntgModal.RampRemove)
      return
    }
    setShowModal(IntgModal.RampCreate)
  }

  const onClickZiphq = () => {
    if (integrations && integrations.ziphq) {
      setShowModal(IntgModal.ZiphqRemove)
      return
    }
    setShowModal(IntgModal.ZiphqCreate)
  }
  const onClickIronclad = () => {
    if (
      integrations &&
      (integrations.ironclad_main || integrations.ironclad_eu)
    ) {
      setShowModal(IntgModal.IroncladRemove)
      return
    }
    setShowModal(IntgModal.IroncladRedirect)
  }

  // NetSuite connection
  const getMergeLinkTokenNS = async () => {
    const netsuitePath = addImpersonationParams(
      '/integrations/create-link-token/netsuite',
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const resp = await backendRequest(netsuitePath, {
      method: 'POST',
    })
    setLinkTokenNS(resp.link_token)
  }

  // step 3: Send public_token to server which exchanges it with account_token
  const onSuccessNS = useCallback(
    (public_token: string) => {
      const netsuitePath = addImpersonationParams(
        `/integrations/account-token/${public_token}/netsuite`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      backendRequest(netsuitePath)
      // TODO: add a toast message
    },
    [reqOrgId, reqUserId, impersonate]
  )

  const { open: openNS } = useMergeLink({
    linkToken: linkTokenNS,
    onSuccess: onSuccessNS,
  })

  const onClickNetsuite = () => {
    if (integrations && integrations.netsuite) {
      setShowModal(IntgModal.NetsuiteRemove)
      return
    }
    // step 1: get link token for integration
    getMergeLinkTokenNS()
    // step 2: open modal for netsuite integration
    openNS()
  }
  // TODO: add SageIntacct integration and test before onboarding Sage customer
  // SageIntacct connection
  // const getMergeLinkTokenSI = async () => {
  //   const resp = await backendRequest(
  //     `/integrations/create-link-token/sage-intacct`,
  //     {
  //       method: 'POST',
  //     }
  //   )
  //   setLinkTokenSI(resp.link_token)
  // }

  // // step 3: Send public_token to server which exchanges it with account_token
  // const onSuccessSI = useCallback((public_token: string) => {
  //   backendRequest(`/integrations/account-token/${public_token}/sage_intacct`)
  //   // TODO: add a toast message
  // }, [])

  // const { open: openSI } = useMergeLink({
  //   linkToken: linkTokenSI,
  //   onSuccess: onSuccessSI,
  // })

  // const onClickSageIntacct = () => {
  //   if (integrations && integrations.sage_intacct) {
  //     setShowModal(IntgModal.SageIntacctRemove)
  //     return
  //   }

  //   getMergeLinkTokenSI()

  //   openSI()
  // }

  const onClickGoogleWorkspace = () => {
    if (integrations && integrations.google_workspace) {
      setShowModal(IntgModal.GoogleWorkspaceAddRemove)
      return
    }
    setShowModal(IntgModal.GoogleWorkspaceRedirect)
  }
  const onClickJamf = () => {
    if (integrations && integrations.jamf) {
      setShowModal(IntgModal.JamfRemove)
      return
    }
    setShowModal(IntgModal.JamfCreate)
  }
  const onClickMicrosoft365 = () => {
    if (integrations && integrations.microsoft_365) {
      setShowModal(IntgModal.MicrosoftRemove)
      return
    }
    setShowModal(IntgModal.MicrosoftRedirect)
  }
  const onClickOkta = () => {
    if (integrations && integrations.okta_logs) {
      setShowModal(IntgModal.OktaRemove)
      return
    }
    setShowModal(IntgModal.OktaCreate)
  }

  const onClickExtension = (extension: string) => {
    let extensionURL = ''
    switch (extension) {
      case 'chrome_extension':
        extensionURL =
          'https://chrome.google.com/webstore/detail/diminish-saas-usage-monit/bpkibljodllhlanokipjabibokdjdhch'
        break
      case 'firefox_extension':
        extensionURL =
          'https://addons.mozilla.org/en-US/firefox/addon/diminish-saas-usage-monitor/'
        break
      case 'safari_extension':
        extensionURL =
          'https://apps.apple.com/us/app/diminish-saas-usage-monitor/id6451277774'
        break
      case 'edge_extension':
        extensionURL =
          'https://microsoftedge.microsoft.com/addons/detail/diminish-saas-usage-mon/cdpokdafdmmjdghhccegdijejbllkbnl'
        break
    }
    window.open(extensionURL, '_blank')
  }
  const onClickGoogleWorkspaceSettings = () => {
    setShowModal(IntgModal.GoogleWorkspaceSettings)
  }

  const onClickMicrosoftSettings = () => {
    setShowModal(IntgModal.MicrosoftSettings)
  }

  const onClickHRIS = () => {
    setShowModal(IntgModal.HRISCSVImporter)
  }

  useEffect(() => {
    if (!window.location.href.includes('/quickbooks')) {
      return
    }
    completeQuickbooksSetup(setShowModal, impersonate, reqOrgId, reqUserId)
  }, [impersonate, reqOrgId, reqUserId])

  useEffect(() => {
    if (!window.location.href.includes('/xero')) {
      return
    }
    completeXeroSetup(setShowModal, impersonate, reqOrgId, reqUserId)
  }, [reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    if (!window.location.href.includes('/ironclad')) {
      return
    }

    const params = new URLSearchParams(
      window.location.href.substring(window.location.href.indexOf('?'))
    )

    if (params.get('error')) {
      // clear query string without refreshing the page. prevents double requests by state change
      window.history.replaceState({}, '', '/integrations')
      setShowModal(IntgModal.IroncladFail)
      return
    }

    if (window.location.href.includes('/ironclad_main')) {
      setIroncladEnv('ironclad_main')
      setShowModal(IntgModal.IroncladCustomerId)
      return
    }

    if (window.location.href.includes('/ironclad_eu')) {
      setIroncladEnv('ironclad_eu')
      setShowModal(IntgModal.IroncladCustomerId)
      return
    }
  }, [reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    if (window.location.href.includes('/microsoft365_user_inbox')) {
      completeMicrosoftSetup(
        setShowModal,
        'USER_INBOX',
        impersonate,
        reqOrgId,
        reqUserId
      )
      return
    }

    if (window.location.href.includes('/microsoft365_user')) {
      completeMicrosoftSetup(
        setShowModal,
        'USER',
        impersonate,
        reqOrgId,
        reqUserId
      )
      return
    }
  }, [reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    if (integrations !== undefined) {
      return
    }

    const getIntegrations = async () => {
      const reqPath = addImpersonationParams(
        '/integrations',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast('Failed to fetch integration status', ToastType.ERROR)
        return
      }
      setIntegrations(resp)

      if (resp.ironclad_main) {
        setIroncladEnv('ironclad_main')
      } else if (resp.ironclad_eu) {
        setIroncladEnv('ironclad_eu')
      }
    }
    getIntegrations()
  }, [integrations, reqOrgId, reqUserId, impersonate])

  const dismissModal = () => {
    setShowModal(0)
    setIntegrations(undefined)
  }

  if (integrations === undefined) {
    return <Loading large />
  }

  return (
    <>
      <IntegrationTitle>
        Accounting and Finance{' '}
        <IntegrationCount>({ramp ? 4 : 3})</IntegrationCount>
      </IntegrationTitle>
      <CardsContainer>
        <CardsWrapper>
          <IntegrationCard
            name="QuickBooks Online"
            src={QuickBooks}
            onClick={onClickQuickBooks}
            connected={integrations.quickbooks}
          />
        </CardsWrapper>
        <CardsWrapper>
          <IntegrationCard
            name="NetSuite"
            src={Netsuite}
            onClick={onClickNetsuite}
            connected={integrations.netsuite}
          />
        </CardsWrapper>
        <CardsWrapper>
          <IntegrationCard
            name="Xero"
            src={Xero}
            onClick={onClickXero}
            connected={integrations.xero}
          />
        </CardsWrapper>
        {ramp && (
          <CardsWrapper>
            <IntegrationCard
              name="Ramp"
              src={Ramp}
              onClick={onClickRamp}
              connected={integrations.ramp}
            />
          </CardsWrapper>
        )}
        {/* <CardsWrapper>
          <IntegrationCard
            name="Sage Intacct"
            src={SageIntacct}
            onClick={onClickSageIntacct}
            connected={integrations.sage_intacct}
          />
        </CardsWrapper> */}
      </CardsContainer>

      <IntegrationTitle>
        Identity Providers <IntegrationCount>(2)</IntegrationCount>
      </IntegrationTitle>
      <CardsContainer>
        <CardsWrapper>
          <IntegrationCard
            name="Google Workspace"
            src={GoogleWorkspace}
            onClick={onClickGoogleWorkspace}
            onClickSettings={onClickGoogleWorkspaceSettings}
            connected={integrations.google_workspace}
            allowMultiple
          />
          {microsoft365 && (
            <IntegrationCard
              name="Microsoft 365"
              src={Microsoft365}
              onClick={onClickMicrosoft365}
              onClickSettings={onClickMicrosoftSettings}
              connected={integrations.microsoft_365}
            />
          )}
        </CardsWrapper>
      </CardsContainer>

      {jamf && orgJamf ? (
        <>
          <IntegrationTitle>
            Mobile Device Management <IntegrationCount>(1)</IntegrationCount>
          </IntegrationTitle>
          <CardsContainer>
            <CardsWrapper>
              <IntegrationCard
                name="Jamf Pro"
                src={Jamf}
                onClick={onClickJamf}
                connected={integrations.jamf}
              />
            </CardsWrapper>
          </CardsContainer>
        </>
      ) : (
        <></>
      )}
      {oktaLogs && oktaLogsAll ? (
        <>
          <IntegrationTitle>
            SSO Logs <IntegrationCount>(1)</IntegrationCount>
          </IntegrationTitle>
          <CardsContainer>
            <CardsWrapper>
              <IntegrationCard
                name="Okta SSO Logs"
                src={Okta}
                onClick={onClickOkta}
                connected={integrations.okta_logs}
              />
            </CardsWrapper>
          </CardsContainer>
        </>
      ) : (
        <></>
      )}

      {ziphq && orgZiphq && (
        <>
          <IntegrationTitle>
            Procurement Workflows <IntegrationCount>(1)</IntegrationCount>
          </IntegrationTitle>
          <CardsContainer>
            <CardsWrapper>
              <IntegrationCard
                name="ZipHQ"
                src={ZipHQ}
                onClick={onClickZiphq}
                connected={integrations.ziphq}
              />
            </CardsWrapper>
          </CardsContainer>
        </>
      )}
      {ironclad && (
        <>
          <IntegrationTitle>
            Contract Lifecycle Management{' '}
            <IntegrationCount>(1)</IntegrationCount>
          </IntegrationTitle>
          <CardsContainer>
            <CardsWrapper>
              <IntegrationCard
                name="Ironclad"
                src={IroncladIcon}
                onClick={onClickIronclad}
                connected={
                  integrations.ironclad_main || integrations.ironclad_eu
                }
              />
            </CardsWrapper>
          </CardsContainer>
        </>
      )}
      <IntegrationTitle>
        Browser Extensions <IntegrationCount>(4)</IntegrationCount>
      </IntegrationTitle>
      <CardsWrapper>
        <IntegrationCard
          name="Chrome Extension"
          src={Chrome}
          onClick={() => onClickExtension('chrome_extension')}
          connected={integrations.chrome_extension}
        />
        <IntegrationCard
          name="Firefox Add-on"
          src={Firefox}
          onClick={() => onClickExtension('firefox_extension')}
          connected={integrations.firefox_extension}
        />
        <IntegrationCard
          name="Safari Extension"
          src={Safari}
          onClick={() => onClickExtension('safari_extension')}
          connected={integrations.safari_extension}
        />
        <IntegrationCard
          name="Edge Extension"
          src={Edge}
          onClick={() => onClickExtension('edge_extension')}
          connected={integrations.edge_extension}
        />
      </CardsWrapper>
      <IntegrationTitle>
        HRIS <IntegrationCount>(1)</IntegrationCount>
      </IntegrationTitle>
      <CardsWrapper>
        <IntegrationCard
          name="CSV Importer"
          src={CSV}
          onClick={() => onClickHRIS()}
          connected={integrations.edge_extension}
        />
      </CardsWrapper>

      <QuickbooksModalHandler
        modal={showModal}
        onDismiss={dismissModal}
        setModal={setShowModal}
      />
      <NetsuiteModalHandler
        modal={showModal}
        onDismiss={dismissModal}
        setModal={setShowModal}
      />
      <XeroModalHandler
        modal={showModal}
        onDismiss={dismissModal}
        setModal={setShowModal}
      />
      <IroncladModalHandler
        modal={showModal}
        onDismiss={dismissModal}
        setModal={setShowModal}
        ironcladEnv={ironcladEnv}
      />
      <GoogleWorkspaceModalHandler
        modal={showModal}
        onDismiss={dismissModal}
        setModal={setShowModal}
      />
      <MicrosoftModalHandler
        modal={showModal}
        onDismiss={dismissModal}
        setModal={setShowModal}
      />
      <OktaModalHandler
        modal={showModal}
        onDismiss={dismissModal}
        setModal={setShowModal}
      />
      <HRISModalHandler
        modal={showModal}
        onDismiss={() => setShowModal(0)}
        setModal={setShowModal}
      />
      <RampModalHandler
        modal={showModal}
        onDismiss={dismissModal}
        setModal={setShowModal}
      />
      <JamfModalHandler
        modal={showModal}
        onDismiss={dismissModal}
        setModal={setShowModal}
      />
    </>
  )
}

const GearWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '10px',
  flexGrow: 1,
})

const TextWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '4px',
})

const IntegrationCard = ({
  name,
  src,
  onClick,
  onClickSettings,
  connected,
  allowMultiple,
}: {
  name: string
  src: string
  onClick?: () => void
  onClickSettings?: () => void
  connected?: boolean
  allowMultiple?: boolean
}) => (
  <IntegrationWrapper>
    <TextWrapper>
      <ImageWrapper>
        <img src={src} alt={`${name}-logo`} style={{ width: '32px' }} />
      </ImageWrapper>
      <HeadingSmallest color="textDefault">{name}</HeadingSmallest>
      {onClickSettings ? (
        <GearWrapper>
          <GearIcon onClick={onClickSettings} />
        </GearWrapper>
      ) : (
        <></>
      )}
    </TextWrapper>
    <Divider style={{ margin: '8px 0' }} />
    <ButtonWrapper>
      {connected ? <IntegratedBadge /> : <span />}
      {onClick ? (
        <LinkButton onClick={onClick}>
          {connected ? (allowMultiple ? 'Add/Remove' : 'Remove') : 'Add'}
        </LinkButton>
      ) : (
        <span />
      )}
    </ButtonWrapper>
  </IntegrationWrapper>
)

const IntegratedBadge = () => (
  <BadgeWrapper>
    Integrated
    <GreenCheckCircle
      transform="scale(0.7)"
      style={{ width: '14px', height: '14px' }}
    />
  </BadgeWrapper>
)

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const BadgeWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 25px;
  border-radius: 12px;
  padding: 0 12px;
  font-size: 16px;
  background-color: ${theme.color.successGreenBackground};
  color: ${theme.color.textDefault};
`

export default Integrations
