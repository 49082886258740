import React, { ReactNode, useState } from 'react'
import { IntgModal } from '.'
import Button, { GhostButton } from '../components/Button'
import Divider from '../components/Divider'
import Modal, { ButtonWrapper } from '../components/Modal'
import { HeadingMedium, HeadingSmallest } from '../components/Text'
import theme from '../Theme'
import { addImpersonationParams, backendRequest } from '../utils/utils'
import useImpersonation from '../hooks/useImpersonation'
import Loading from '../assets/Loading'
import { TextField } from '../components/InputFields'
import { CodeInline } from '../components/CodeBlock'
import { showToast, ToastType } from '../utils/toastify'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const JamfModalHandler = ({
  modal,
  onDismiss,
  setModal,
}: {
  modal: IntgModal
  onDismiss: () => void
  setModal: (value: React.SetStateAction<IntgModal>) => void
}) => {
  const { jamf, orgJamf } = useFlags()
  if (!jamf || !orgJamf) return <></>

  switch (modal) {
    case IntgModal.JamfCreate:
      return <JamfCreateModal setModal={setModal} onDismiss={onDismiss} />
    case IntgModal.JamfRemove:
      return (
        <JamfRemoveModal
          onRemoveSuccess={() => setModal(IntgModal.JamfRemovalSuccess)}
          onDismiss={onDismiss}
        />
      )
    case IntgModal.JamfRemovalSuccess:
      return <JamfRemoveSuccessModal onDismiss={onDismiss} />
    case IntgModal.JamfSuccess:
      return <JamfSuccessModal onDismiss={onDismiss} />
    case IntgModal.JamfFailure:
      return <JamfFailureModal onDismiss={onDismiss} />
    default:
      return <></>
  }
}

const JamfModalWrapper = ({
  onDismiss,
  onClick,
  heading,
  body,
  buttonTxt,
  disableBtn,
  loading,
}: {
  onDismiss: () => void
  onClick: () => Promise<void> | void
  heading: string
  body: ReactNode
  buttonTxt: string
  disableBtn?: boolean
  loading: boolean
}) => (
  <Modal
    onDismiss={onDismiss}
    style={{ width: '700px', borderColor: theme.color.textPurple }}
  >
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        <HeadingMedium color="textDefault">{heading}</HeadingMedium>
      </div>
      <Divider />
      {body}
      <Divider />
      <ButtonWrapper>
        <GhostButton
          onClick={onDismiss}
          style={{ padding: '14px 24px', borderColor: theme.color.textPurple }}
        >
          Cancel
        </GhostButton>
        {loading ? (
          <Button onClick={onClick} disabled={true}>
            <Loading />
          </Button>
        ) : (
          <Button onClick={onClick} disabled={disableBtn}>
            {buttonTxt}
          </Button>
        )}
      </ButtonWrapper>
    </div>
  </Modal>
)

type JamfPayload = {
  client_id?: string
  client_secret?: string
  domain_url?: string
}

const JamfCreateModal = ({
  onDismiss,
  setModal,
}: {
  onDismiss: () => void
  setModal: (value: React.SetStateAction<IntgModal>) => void
}) => {
  const [payload, setPayload] = useState<JamfPayload>({})
  const [loading, setLoading] = useState<boolean>(false)

  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  const onClick = () => {
    setLoading(true)
    const createIntegration = async () => {
      const reqPath = addImpersonationParams(
        '/integrations/jamf_pro',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath, {
        method: 'POST',
        body: JSON.stringify({
          client_id: payload.client_id,
          client_secret: payload.client_secret,
          domain_url: payload.domain_url,
        }),
      })
      if (resp.error) {
        setModal(IntgModal.JamfFailure)
        return
      }
      setModal(IntgModal.JamfSuccess)
    }
    createIntegration()
  }

  return (
    <JamfModalWrapper
      onDismiss={onDismiss}
      onClick={onClick}
      heading="Integrate with Jamf Pro"
      body={
        <>
          <HeadingSmallest color="textDefault">
            Connect your Jamf account to extract desktop usage data. Follow
            these steps to create a new API Key:
          </HeadingSmallest>
          <HeadingSmallest>
            <ol
              style={{
                paddingLeft: '30px',
                margin: '0',
              }}
            >
              <li>
                On your jamf Pro account, navigate to Settings and create a new
                API Role
              </li>
              <li>
                Give it name such as <CodeInline>Diminish API Role</CodeInline>
              </li>
              <li>Next, add the following permissions to your role:</li>
              <ul>
                <li>
                  <CodeInline>Read Computers</CodeInline>
                </li>
                <li>
                  <CodeInline>Read Mobile Device Applications</CodeInline>
                </li>
                <li>
                  <CodeInline>Read User</CodeInline>
                </li>
                <li>
                  <CodeInline>Read Mac Applications</CodeInline>
                </li>
              </ul>
              <li>
                Once you save your role, go to API Clients and create a new one.
              </li>
              <li>
                Use the role from the previous step. You can set the Access
                Token Lifetime to 3,600 seconds.
              </li>
              <li>
                Copy and paste the generated Client ID and Client Secret as well
                as your Jamf Domain into the fields below
              </li>
            </ol>
          </HeadingSmallest>
          <Divider />
          <TextField
            label={'Jamf Domain'}
            placeholder="https://your-domain.jamfcloud.com"
            onChange={(data) => setPayload({ ...payload, domain_url: data })}
          />
          <TextField
            label={'Jamf Client ID'}
            onChange={(data) => setPayload({ ...payload, client_id: data })}
          />
          <TextField
            label={'Jamf Client Secret'}
            onChange={(data) => setPayload({ ...payload, client_secret: data })}
          />
        </>
      }
      buttonTxt="Create"
      disableBtn={
        !payload.client_id || !payload.client_secret || !payload.domain_url
      }
      loading={loading}
    />
  )
}

const JamfRemoveModal = ({
  onRemoveSuccess,
  onDismiss,
}: {
  onRemoveSuccess: () => void
  onDismiss: () => void
}) => {
  const [loading, setLoading] = useState<boolean>(false)

  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  const onClick = () => {
    setLoading(true)
    const createIntegration = async () => {
      const googleWorkspacePath = addImpersonationParams(
        '/integrations/jamf_pro',
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(googleWorkspacePath, {
        method: 'DELETE',
      })
      if (resp.error) {
        showToast('Failed to remove Jamf integration', ToastType.ERROR)

        return
      }
      onRemoveSuccess()
    }
    createIntegration()
  }

  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '450px', borderColor: theme.color.errorRed }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <HeadingMedium color="textDefault">
            Are you sure you want to remove the Jamf integration?
          </HeadingMedium>
        </div>
        <Divider />
        <HeadingSmallest>
          If you remove this integration, Diminish will no longer pull desktop
          usage of users in your Jamf account.
        </HeadingSmallest>
        <Divider />
        <ButtonWrapper>
          <GhostButton
            onClick={onDismiss}
            style={{
              padding: '14px 24px',
              borderColor: theme.color.errorRed,
            }}
          >
            Cancel
          </GhostButton>
          <Button onClick={onClick} disabled={loading} color="errorRed">
            {loading ? <Loading /> : 'Remove'}
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}

const JamfRemoveSuccessModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '450px', borderColor: theme.color.successGreen }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <HeadingMedium color="textDefault">
          Jamf Integration Removed Successfully
        </HeadingMedium>
        <HeadingSmallest style={{ margin: '8px 0' }}>
          You have successfully removed your Jamf integration. Diminish can no
          longer pull desktop usage activity from your Jamf account.
        </HeadingSmallest>
        <Divider />
        <ButtonWrapper>
          <Button
            onClick={onDismiss}
            style={{
              backgroundColor: theme.color.successGreen,
              borderColor: theme.color.successGreen,
              width: '100px',
            }}
          >
            Ok
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}
const JamfSuccessModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '450px', borderColor: theme.color.successGreen }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <HeadingMedium color="textDefault">
          Jamf Integration Successful
        </HeadingMedium>
        <HeadingSmallest style={{ margin: '8px 0' }}>
          You have successfully connected your Jamf account. Diminish will start
          pulling desktop usage activity of users in your Jamf account.
        </HeadingSmallest>
        <Divider />
        <ButtonWrapper>
          <Button
            onClick={onDismiss}
            style={{
              backgroundColor: theme.color.successGreen,
              borderColor: theme.color.successGreen,
              width: '100px',
            }}
          >
            Ok
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}

const JamfFailureModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '450px', borderColor: theme.color.errorRed }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <HeadingMedium color="textDefault">
            Something Went Wrong
          </HeadingMedium>
        </div>
        <Divider />
        <HeadingSmallest>
          There was an issue creating the integration. Please contact support.
        </HeadingSmallest>
        <Divider />
        <ButtonWrapper>
          <Button
            onClick={onDismiss}
            style={{
              backgroundColor: theme.color.errorRed,
              borderColor: theme.color.errorRed,
              width: '100px',
            }}
          >
            Ok
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}
